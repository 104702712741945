// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-bio-tsx": () => import("./../../../src/pages/bio.tsx" /* webpackChunkName: "component---src-pages-bio-tsx" */),
  "component---src-pages-david-williams-obituary-tsx": () => import("./../../../src/pages/david-williams-obituary.tsx" /* webpackChunkName: "component---src-pages-david-williams-obituary-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-texts-tsx": () => import("./../../../src/pages/texts.tsx" /* webpackChunkName: "component---src-pages-texts-tsx" */),
  "component---src-pages-works-tsx": () => import("./../../../src/pages/works.tsx" /* webpackChunkName: "component---src-pages-works-tsx" */),
  "component---src-templates-image-post-tsx": () => import("./../../../src/templates/image-post.tsx" /* webpackChunkName: "component---src-templates-image-post-tsx" */),
  "component---src-templates-tag-page-tsx": () => import("./../../../src/templates/tag-page.tsx" /* webpackChunkName: "component---src-templates-tag-page-tsx" */),
  "component---src-templates-year-page-tsx": () => import("./../../../src/templates/year-page.tsx" /* webpackChunkName: "component---src-templates-year-page-tsx" */)
}

